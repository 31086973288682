<template>
  <div class="devcomp">
    <el-row>
      <el-col :span="24">
        <div class="search">
          <el-form :inline="true" class="demo-form-inline">


            <el-form-item label="按所属用户筛选：">
              <el-select @change="onRefresh" v-model="usvalue" placeholder="请选择">
                <el-option v-for="item in uselect" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item style="margin-left: 20px" label="按状态筛选：">
              <el-select @change="onRefresh" v-model="ssvalue" placeholder="请选择" style="width: 140px;">
                <el-option v-for="item in sselect" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="  ">
              <el-input clearable @keyup.enter.native="onRefresh()" @clear="onRefresh()" placeholder="搜索设备名"
                v-model="searchinput" style="width: 400px;">
                <template slot="prepend">设备名称查找:</template>
                <el-button slot="append" @click="onRefresh" icon="el-icon-search">查询</el-button>
              </el-input>
            </el-form-item>
            <el-form-item label="  ">
              <el-input clearable @keyup.enter.native="onRefresh()" @clear="onRefresh()" placeholder="支持模糊搜索编号SN"
                v-model="searchinputSN" style="width: 440px;">
                <template slot="prepend">设备编号(SN)查找:</template>
                <el-button slot="append" @click="onRefresh" icon="el-icon-search">查询</el-button>
              </el-input>
            </el-form-item>

            <el-form-item label="  ">
              <el-checkbox v-model="ordercheck" @change="onRefresh">按活跃时间排序</el-checkbox>
            </el-form-item>

          </el-form>

        </div>
        <div class="search">


        </div>

        <el-table :data="tbdata" border stripe height="660" style="width: 100%; margin: 3px">
          <el-table-column prop="sn" align="center" label="SN" width="145">
          </el-table-column>
          <el-table-column prop="name" label="设备名称" align="center">
          </el-table-column>

          <!--          <el-table-column prop="model" width="80" label="型号" align="center">-->
          <!--          </el-table-column>-->
          <el-table-column prop="phone" width="130" label="联系方式" align="center">
          </el-table-column>


          <el-table-column prop="status" width="90" label="状态" align="center">
            <template slot-scope="scope">
              <el-tag :type="getStatusType(scope.row.status)" disable-transitions>
                {{ scope.row.status }}
              </el-tag>
            </template>
          </el-table-column>

          <el-table-column prop="device_temp" width="80" label="仓温" align="center" :formatter="temperatureFormatter">
          </el-table-column>

          <el-table-column prop="heat_temp" width="80" label="水温" align="center" :formatter="temperatureFormatter">
          </el-table-column>

<!--          <el-table-column prop="counts" width="105" label="剩余次数" align="center">-->
<!--          </el-table-column>-->

          <el-table-column prop="expiration" width="105" label="到期时间" align="center">
          </el-table-column>

          <el-table-column prop="today" width="105" label="今日次数" align="center">
          </el-table-column>

          <el-table-column prop="total" width="105" label="总次数" align="center">
          </el-table-column>

          <el-table-column :width="btnwidth">
            <template slot="header">
              <el-button type="primary" icon="el-icon-refresh" style="margin-left: 30px;width: 140px"
                @click="onRefresh">刷新列表
              </el-button>
              <el-button type="primary" icon="el-icon-download" style="margin-left: 30px;width: 140px" plain
                @click="ExportDeviceExcel">导出本页列表
              </el-button>
            </template>
            <template slot-scope="scope">
              <!-- <el-button size="mini" type="success" plain @click="DeviceTrun(scope.$index,'1')">启动设备</el-button>
              <el-button size="mini" type="danger" plain @click="DeviceTrun(scope.$index,'0')">停止设备</el-button> -->
              <!-- 
              <span v-if="pg_list.charge_device">
                <el-divider direction="vertical"></el-divider>
                <el-button size="mini" @click="OpenRechargeDialog(scope.$index)" type="primary"
                           plain>次数充值</el-button>
              </span>
              <span v-if="pg_list.charge_order_export">
                <el-button style="margin-left: 10px" @click="ExportChargeDialog(scope.$index)" size="mini" type="primary">充次记录</el-button>
              </span> -->

              <span>
                <el-button icon="el-icon-edit" style="margin-left: 1px" size="mini"
                  @click="OpenModifyDialog(scope.$index)">修改信息</el-button>
              </span>

<!--              <span>-->
<!--                <el-divider direction="vertical"></el-divider>-->
<!--                <el-button :disabled="scope.row.model == 'HZ1'" icon="el-icon-money" size="mini"-->
<!--                           @click="OpenRechargeDialog(scope.$index)" type="success" plain>次数充值</el-button>-->
<!--              </span>-->

              <span>
                <el-divider direction="vertical"></el-divider>
                <el-button :disabled="scope.row.model == 'HZ1'" icon="el-icon-money" size="mini"
                           @click="OpenExpirationDialog(scope.$index)" type="success" plain>到期日期</el-button>
              </span>

              <span>
              <el-divider direction="vertical"></el-divider>
              <el-button :disabled="scope.row.model == 'HZ1'"  size="mini" @click="OpenMapDialog(scope.$index)" icon="el-icon-location-outline"
                         plain>设备定位</el-button>
              </span>

              <span v-if="pg_list.bind_or_not">
                <el-divider direction="vertical"></el-divider>
                <el-button icon="el-icon-coordinate" style="margin-left: 5px" size="mini" @click="OpenAllocateDialog(scope.$index, 0)"
                           type="primary" plain>设备分配</el-button>
              </span>

              <span v-if="pg_list.device_order_export">
                <el-divider direction="vertical"></el-divider>
                  <el-button v-if="scope.row.mod != 'HZ1'" icon="el-icon-tickets" @click="ExportExcelDialog(scope.$index)"
                             size="mini">使用记录</el-button>
              </span>

<!--              <span v-if="pg_list.device_order_export">-->
<!--                <el-divider direction="vertical"></el-divider>-->
<!--                  <el-popover placement="top" width="220" v-model="scope._vis_export_">-->
<!--                <div style="text-align: center; margin: 0">-->
<!--                  <p>导出Excel报表</p>-->

<!--                  <el-button v-if="scope.row.mod != 'X1'" icon="el-icon-tickets" @click="ExportExcelDialog(scope.$index)"-->
<!--                             size="mini">使用记录</el-button>-->
<!--                  <el-button icon="el-icon-pie-chart" style="margin-left: 10px"-->
<!--                             @click="ExportChargeDialog(scope.$index)" size="mini">充次记录</el-button>-->
<!--                </div>-->
<!--                <el-button size="mini" icon="el-icon-document" type="info" plain slot="reference">导出报表</el-button>-->
<!--              </el-popover>-->
<!--              </span>-->

<!--              <span v-if="pg_list.charge_device">-->
<!--                <el-button style="margin-left: 10px" size="mini"-->
<!--                  @click="OpenModifyDialog(scope.$index)">设备命名</el-button>-->
<!--              </span>-->

              <!--              <span v-if="pg_list.enable_device">-->
              <!--                <el-divider direction="vertical"></el-divider>-->
              <!--                <span v-if="enen">-->
              <!--                <el-button size="mini" @click="EnableDisableDevice(scope.$index,1)" type="success"-->
              <!--                           plain>设备启用</el-button>-->
              <!--                </span>-->
              <!--                <span v-if="enen==false">-->
              <!--                <el-button size="mini" @click="EnableDisableDevice(scope.$index,0)" type="info"-->
              <!--                           plain>设备禁用</el-button>-->
              <!--                </span>-->
              <!--              </span>-->


<!--              <span v-if="pg_list.delete_device">-->
<!--                <el-divider direction="vertical"></el-divider>-->
<!--                <el-button @click="DeleteDeviceCommit(scope.$index)" size="mini" type="danger">设备删除</el-button>-->
<!--              </span>-->
            </template>
          </el-table-column>
        </el-table>
      </el-col>


    </el-row>

    <div style="display: flex;align-items: center;justify-content:flex-start">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currPage"
        :page-sizes="[10, 20, 30, 50]" :page-size="currSize" layout="total, sizes, prev, pager, next, jumper"
        :total="currTotal">
      </el-pagination>


      <span style="margin-left: 40px">
        列表自动刷新:
        <el-switch style="margin-right: 7px" v-model="autoRefreshFlag" @change="handleSwitchChange">
        </el-switch>
        <span v-if="autoRefreshFlag">开</span>
        <span v-if="!autoRefreshFlag">关</span>
      </span>

      <!--      <span style="margin-left: 40px">-->
      <!--            <el-checkbox v-model="ordercheck" @change="onRefresh">按设备活跃时间排序</el-checkbox>-->
      <!--          </span>-->

    </div>

    <!-- 充值次数 Start -->
    <el-dialog title="设备次数充值" :visible.sync="RechargeTimesDialog" width="400px">
      <el-form v-loading="chargeLoading">
        <el-form-item label="充值次数：" label-width="100px">
          <el-input type="number" v-model="RechargeEditor" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <p style="margin-left: 16px"><i>当前剩余可分配次数:</i> <b style="margin-left: 4px">{{
        info.atimes
      }}</b></p>
      <div slot="footer" class="dialog-footer">
        <el-button @click="RechargeTimesDialog = false">取 消</el-button>
        <el-button type="success" @click="RechargeCommit">充 次</el-button>
      </div>
    </el-dialog>
    <!-- 充值次数 END -->

    <!-- 到期设置 Start -->

    <el-dialog title="到期设置设置" :visible.sync="ExpirationDialog" width="400px">
      <el-form v-loading="chargeLoading">
        <el-form-item label="设备到期日期：" label-width="120px">
          <el-date-picker
              v-model="expiration"
              align="right"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              :picker-options="exppickerOptions">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="ExpirationDialog = false">取 消</el-button>
        <el-button type="success" @click="ExpirationCommit">设 定</el-button>
      </div>
    </el-dialog>
    <!-- 到期设置 END -->

    <!-- 修改床名字 Start -->
    <el-dialog :title="'修改设备信息 （设备编号：' + modifySN + '）'" :visible.sync="modifyDeviceNameDialog" width="500px">
      <el-form>
        <el-form-item label="设备名称" label-width="80px">
          <el-input clearable v-model="modifyEditor" autocomplete="off" maxlength="16" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="联系方式" label-width="80px">
          <el-input clearable v-model="modifyUserPhone" autocomplete="off" maxlength="15" show-word-limit></el-input>
        </el-form-item>

        <el-form-item label="收货区域" label-width="80px">
          <el-cascader style="width: 380px" size="large" :options="regionData" v-model="modifySelectedArea">
          </el-cascader>
        </el-form-item>
        <el-form-item label="收货地址" label-width="80px">
          <el-input clearable v-model="modifyAddress" autocomplete="off" maxlength="120" show-word-limit></el-input>
        </el-form-item>


        <div style="margin: 10px">备注信息：</div>
        <el-input type="textarea" placeholder="请输入内容" v-model="modifyComments" :autosize="{ minRows: 4, maxRows: 12 }"
                  maxlength="1000" show-word-limit>
        </el-input>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="modifyDeviceNameDialog = false">取 消</el-button>
        <el-button type="primary" @click="ModifyNameAddrCommentCommit">保 存</el-button>
      </div>
    </el-dialog>
    <!-- 修改床名字 END -->

    <!--    地图 Start-->
    <el-dialog :title="'设备定位 - ' + MapTitle + '       -->> 地址: ' + MapAddress" :visible.sync="MapDialog" width="1300px">
      <el-form :inline="true">
        <el-form-item>
          <el-radio-group v-model="MapSelect" @change="GetMapInfo">
            <el-radio-button label="设备定位"></el-radio-button>
            <el-radio-button label="标注定位"></el-radio-button>
            <el-radio-button label="手动标定位置"></el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="省市区域：">
          <el-cascader style="width: 210px" size="large" :options="regionData" v-model="mapSelectedArea">
          </el-cascader>
        </el-form-item>

        <el-form-item label="地址：">
          <el-input clearable style="width: 160px" v-model="MapAddressInput" placeholder="详细地址"></el-input>
        </el-form-item>

        <el-form-item vlabel="经纬度：">
          <el-input clearable style="width: 150px" v-model="MapMarked" placeholder="输入坐标"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="LocationMarkCommit">保存</el-button>
        </el-form-item>
      </el-form>
      <div class="block" style="border-top: 1px dashed #000;">
        <iframe :src="MapURL" frameborder="0" style="width: 100%; height: 650px;"></iframe>
      </div>
    </el-dialog>
    <!--    地图END-->

    <el-dialog title="设备分配" :visible.sync="DeviceAllocateDialog" width="500px">
      <el-form>
        <el-form-item label="选择设备所属用户" label-width="160px">
          <el-select v-model="AllocValue" placeholder="请选择">
            <el-option v-for="item in AllocList" :key="item.uid" :label="item.name" :value="item.uid">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="DeviceAllocateDialog = false">取 消</el-button>
        <el-button type="primary" @click="AllocateCommit">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 设备分配 END -->

    <!-- 导出报表 Start -->
    <el-dialog title="导出报表" :visible.sync="ExcelDialog" width="500px">

      <div class="block">
        <span style="margin-right: 5px">选择导出时间：</span>
        <el-date-picker v-model="excelTime" type="daterange" align="right" unlink-panels range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions">
        </el-date-picker>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="ExcelDialog = false">取 消</el-button>
        <el-button type="primary" @click="ExcelCommit">导 出</el-button>
      </div>
    </el-dialog>
    <!-- 导出报表 END -->

    <!-- 导出充次记录 +2024年1月29日 Start -->
    <el-dialog title="导出充次记录" :visible.sync="ChargeDialog" width="500px">

      <div class="block">
        <span style="margin-right: 5px">选择导出时间：</span>
        <el-date-picker v-model="excelTime" type="daterange" align="right" unlink-panels range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions">
        </el-date-picker>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="ChargeDialog = false">取 消</el-button>
        <el-button type="primary" @click="ExportChargeCommit">导 出</el-button>
      </div>
    </el-dialog>
    <!-- 导出充次记录 END -->
  </div>
</template>

<script>
import { codeToText, regionData } from 'element-china-area-data'

export default {
  data() {
    return {
      autoRefreshFlag: false, // 默认关闭自动刷新
      refreshTimer: null, // 定时器

      currPage: 1,
      currSize: 10,
      currTotal: 0,
      ordercheck: true,

      searchinput: "",
      searchinputSN: "",

      tbdata: [
        {
          "sn": "",
          "name": "",
          "model": "",
          "status": "",
          "counts": 0,
          "today": 0,
          "total": 0
        },],
      uselect: [{
        value: '',
        label: '不限'
      }],
      usvalue: '',
      sselect: [{
        value: '',
        label: '不限'
      }, {
        value: '在线',
        label: '在线'
      }, {
        value: '预热',
        label: '预热'
      }, {
        value: '运行',
        label: '运行'
      }, {
        value: '离线',
        label: '离线'
      }],
      ssvalue: '',

      // 地图
      MapDialog: false,
      MapIsMarked: false,
      MapTitle: '',
      MapURL: '',
      MapLoc: {},
      MapTypeoptions: [{
        value: 'a',
        label: '高德地图'
      }, {
        value: 't',
        label: '腾讯地图(备用)'
      },
      ],
      MapSelect: '设备定位',
      MapSN: '',
      MapMarked: '',
      MapAddressInput: "",
      MapAddress: '',
      MapType: 'a',
      mapSelectedArea: [],


      // 充次
      RechargeTimesDialog: false,
      RechargeEditor: "20",
      RechargeSN: "",
      chargeLoading: false,

      ExpirationDialog:false,
      ExpirationSN:"",
      expiration:"",
      exppickerOptions: {
        disabledDate(time) {
          const date = new Date();
          return time.getTime() < (date.getTime() - 3600 * 1000 * 24*2);
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '一周后',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }, {
          text: '一个月后',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 30);
            picker.$emit('pick', date);
          }
        }, {
          text: '三个月后',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 91);
            picker.$emit('pick', date);
          }
        }, {
          text: '半年后',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 183);
            picker.$emit('pick', date);
          }
        }, {
          text: '一年后',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 365);
            picker.$emit('pick', date);
          }
        }, {
          text: '长期',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 365*10);
            picker.$emit('pick', date);
          }
        }]
      },

      // 修改名字
      modifyDeviceNameDialog: false,
      modifyEditor: "",
      modifyUserPhone: "",
      modifyComments: "",
      modifySN: "",
      modifyCID: "",
      modifyAddress: "",
      regionData,
      modifySelectedArea: [],

      // 设备分配
      DeviceAllocateDialog: false,
      AllocSN: "",
      AllocValue: "",
      AllocList: null,

      // 导出报表
      ExcelDialog: false,
      excelTime: "",
      ExcelSN: "",
      ExcelName: "",
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      orderHeader: {
        i: '序号',
        s: '启动时间',
        e: '停止时间',
        u: '工作时长/分钟',
        m: '备注',
      },
      orderList: [],
      //device list excel
      deviceHeader:
      {
        sn: 'SN',
        name: '设备名称',
        phone: '联系方式',
        status: '状态',
        device_temp: '仓温',
        heat_temp: '水温',
        counts: '剩余次数',
        today: '今日次数',
        month: '每月次数',
        total: '总次数',
        updateTime: '更新时间',
      },

      // 导出充次 + 2024年1月29日
      ChargeDialog: false,
      chargeorderHeader: {
        i: '序号',
        u: '充次操作账号',
        t: '充值次数',
        b: '充次前剩余',
        c: '操作时间',
        m: '备注',
      },
      chargeOrderList: [],

      enen: false,
      pg_list: {
        charge_device: false,
        rename_device: false,
        enable_device: false,
        bind_or_not: false,
        device_order_export: false,
        charge_order_export: false,
        delete_device: false,
      },
      btnwidth: 660,
    }
  },
  created() {
    this.info = this.$store.state.userInfo

    this.onGetVUList();
    this.PG_Query();
    this.onRefresh();

  },
  mounted() {
    if (this.autoRefreshFlag) {
      this.startRefreshTimer();
    }
  },
  beforeDestroy() {
    //this.closeTimer()
    this.clearRefreshTimer();

  },
  methods: {
    handleSwitchChange(value) {
      if (value) {
        this.startRefreshTimer();
      } else {
        this.clearRefreshTimer();
      }
    },
    startRefreshTimer() {
      this.clearRefreshTimer(); // 清除之前的定时器
      this.refreshTimer = setInterval(() => {
        this.onRefresh();
      }, 5000); // 每5秒刷新一次
    },
    clearRefreshTimer() {
      if (this.refreshTimer) {
        clearInterval(this.refreshTimer);
        this.refreshTimer = null;
      }
    },
    // 启动设备
    async DeviceTrun(index, turn) {
      let res = await this.$api.turn_device({
        sn: this.tbdata[index].sn,
        turn: turn,
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: res.msg,
          type: 'success',
        })
      }
    },
    // 导出报表
    ExportExcelDialog(index) {
      this.ExcelSN = this.tbdata[index].sn
      this.ExcelName = this.tbdata[index].name
      this.ExcelDialog = true
    },

    async ExcelCommit() {  // TODO  TEST
      let res = await this.$api.device_order_export({
        sn: this.ExcelSN,
        start: this.excelTime[0],
        end: this.excelTime[1]
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '导出成功',
          type: 'success',
        })
        this.orderList = res.data

        this.ExportOrderExcel()
      }

    },

    //////////////++++++++++++++////////// order excel //++++++++++++++++++++++//////////////////

    async ExportOrderExcel() {  //TODO V2
      console.log('ExportOrderExcel() ')
      //let dev = window.sessionStorage.getItem('devname')
      let name = "设备报表-" + this.ExcelName + '(' + this.excelTime[0] + '-' + this.excelTime[1] + ').xlsx'
      let table = JSON.parse(JSON.stringify(this.orderList))
      let map = []
      let cols = Object.keys(this.orderHeader).length
      let widthMap = {}
      let end = String.fromCharCode(64 + parseInt(cols)) + (table.length + 1)
      // 加入表头
      table.unshift(this.orderHeader)
      // 数据字段过滤
      map = this.$Excel.filterExportData(table, [...Object.keys(this.orderHeader)])
      // 单元格宽度循环设置
      for (let x = 1; x <= cols; x++) {
        widthMap[String.fromCharCode(64 + parseInt(x))] = 120
      }
      widthMap['A'] = 60
      widthMap['E'] = 90

      let colConf = this.$Excel.makeColConfig({ ...widthMap }, 120)
      // 设置样式
      this.$Excel.setExportCellStyle(
        map,
        `A2:${end}`,
        {
          s: {
            fill: { bgColor: { indexed: 64 }, fgColor: { rgb: 'dcdcdc' } },
            font: { color: { rgb: '000000' } },
            alignment: { horizontal: 'center', vertical: 'center' },
          },
        },
        function (
          cell,
          newCell,
          row,
          config,
          currentRow,
          currentCol,
          fieldKey
        ) {
          console.log(row, config, currentCol, fieldKey)
          // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
          return currentRow % 2 === 0 ? newCell : cell // 隔行隔列上色
        }
      )
      this.$Excel.setExportCellStyle(
        map,
        `A2:${end}`,
        {
          s: {
            font: { color: { rgb: '000000' } },
            alignment: { horizontal: 'center', vertical: 'center' },
          },
        },
        function (
          cell,
          newCell,
          row,
          config,
          currentRow,
          currentCol,
          fieldKey
        ) {
          console.log(row, config, currentCol, fieldKey)
          // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
          return currentRow % 2 === 1 ? newCell : cell // 隔行隔列上色
        }
      )
      // 设置样式
      this.$Excel.setExportCellStyle(
        map,
        `A1:${String.fromCharCode(64 + parseInt(cols))}1`,
        {
          s: {
            fill: { bgColor: { indexed: 64 }, fgColor: { rgb: '67c23a' } },
            font: { color: { rgb: 'ffffff' } },
            alignment: { horizontal: 'center', vertical: 'center' },
          },
        },
        function (
          cell,
          newCell,
          row,
          config,
          currentRow,
          currentCol,
          fieldKey
        ) {
          console.log(row, config, currentCol, fieldKey)
          return currentRow === 0 ? newCell : cell // 隔行隔列上色
        }
      )
      // 导出excel
      this.$Excel.exportExcel(map, name, 'xlsx', {
        extend: {
          '!cols': colConf,
        },
      })
    }, //end order excel
    // ------------------------------------------------------------------------------------------------------------

    // 导出充次记录 + 2024年1月29日
    ExportChargeDialog(index) {
      this.ExcelSN = this.tbdata[index].sn
      this.ExcelName = this.tbdata[index].name
      this.ChargeDialog = true
    },

    async ExportChargeCommit() {  // + 2024年1月29日
      let res = await this.$api.charge_order_export({
        sn: this.ExcelSN,
        start: this.excelTime[0],
        end: this.excelTime[1]
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '导出成功',
          type: 'success',
        })
        this.chargeOrderList = res.data

        this.ExportChargeOrderExcel()
      }

    },

    async ExportChargeOrderExcel() {  // + 2024年1月29日
      console.log('ExportChargeOrderExcel() ')
      //let dev = window.sessionStorage.getItem('devname')
      let name = "设备充次记录-" + this.ExcelName + '(' + this.excelTime[0] + '-' + this.excelTime[1] + ').xlsx'
      let table = JSON.parse(JSON.stringify(this.chargeOrderList))
      let map = []
      let cols = Object.keys(this.chargeorderHeader).length
      let widthMap = {}
      let end = String.fromCharCode(64 + parseInt(cols)) + (table.length + 1)
      // 加入表头
      table.unshift(this.chargeorderHeader)
      // 数据字段过滤
      map = this.$Excel.filterExportData(table, [...Object.keys(this.chargeorderHeader)])
      // 单元格宽度循环设置
      for (let x = 1; x <= cols; x++) {
        widthMap[String.fromCharCode(64 + parseInt(x))] = 110
      }
      widthMap['A'] = 50
      widthMap['B'] = 150
      widthMap['C'] = 90
      widthMap['D'] = 90
      widthMap['F'] = 100

      let colConf = this.$Excel.makeColConfig({...widthMap}, 110)
      // 设置样式
      this.$Excel.setExportCellStyle(
          map,
          `A2:${end}`,
          {
            s: {
              fill: {bgColor: {indexed: 64}, fgColor: {rgb: 'dcdcdc'}},
              font: {color: {rgb: '000000'}},
              alignment: {horizontal: 'center', vertical: 'center'},
            },
          },
          function (
              cell,
              newCell,
              row,
              config,
              currentRow,
              currentCol,
              fieldKey
          ) {
            console.log(row, config, currentCol, fieldKey)
            // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
            return currentRow % 2 === 0 ? newCell : cell // 隔行隔列上色
          }
      )
      this.$Excel.setExportCellStyle(
          map,
          `A2:${end}`,
          {
            s: {
              font: {color: {rgb: '000000'}},
              alignment: {horizontal: 'center', vertical: 'center'},
            },
          },
          function (
              cell,
              newCell,
              row,
              config,
              currentRow,
              currentCol,
              fieldKey
          ) {
            console.log(row, config, currentCol, fieldKey)
            // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
            return currentRow % 2 === 1 ? newCell : cell // 隔行隔列上色
          }
      )
      // 设置样式
      this.$Excel.setExportCellStyle(
          map,
          `A1:${String.fromCharCode(64 + parseInt(cols))}1`,
          {
            s: {
              fill: {bgColor: {indexed: 64}, fgColor: {rgb: '1E90FF'}},
              font: {color: {rgb: 'ffffff'}},
              alignment: {horizontal: 'center', vertical: 'center'},
            },
          },
          function (
              cell,
              newCell,
              row,
              config,
              currentRow,
              currentCol,
              fieldKey
          ) {
            console.log(row, config, currentCol, fieldKey)
            return currentRow === 0 ? newCell : cell // 隔行隔列上色
          }
      )
      // 导出excel
      this.$Excel.exportExcel(map, name, 'xlsx', {
        extend: {
          '!cols': colConf,
        },
      })
    }, //end order excel
    // ------------------------------------------------------------------------------------------------------------


    // 启用禁用设备
    async EnableDisableDevice(index, en) {
      let res = await this.$api.enable_device({
        sn: this.tbdata[index].sn,
        en: en,
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '操作成功',
          type: 'success',
        })
        this.onRefresh();
      }
    },
    // 充次弹窗
    OpenRechargeDialog(index) {
      this.RechargeSN = this.tbdata[index].sn
      this.RechargeTimesDialog = true
    },

    OpenExpirationDialog(index){
      this.ExpirationSN = this.tbdata[index].sn
      this.ExpirationDialog = true
    },
    /////////////////////////////////////////// excel //////////////////////////////////////////////////

    ExportDeviceExcel() {  //TODO V2
      console.log('exportExcel() ')
      //let dev = window.sessionStorage.getItem('devname')
      let currentDate = new Date();
      let formattedDate = currentDate.toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '年') + '日';
      let name = this.info.name + "-设备列表-" + formattedDate + '.xlsx';
      let table = JSON.parse(JSON.stringify(this.tbdata))
      let map = []
      let cols = Object.keys(this.deviceHeader).length
      let widthMap = {}
      let end = String.fromCharCode(64 + parseInt(cols)) + (table.length + 1)
      // 加入表头
      table.unshift(this.deviceHeader)
      // 数据字段过滤
      map = this.$Excel.filterExportData(table, [...Object.keys(this.deviceHeader)])
      // 单元格宽度循环设置
      for (let x = 1; x <= cols; x++) {
        widthMap[String.fromCharCode(64 + parseInt(x))] = 70
      }
      widthMap['A'] = 100
      widthMap['B'] = 120
      widthMap['C'] = 100
      widthMap['K'] = 120

      let colConf = this.$Excel.makeColConfig({ ...widthMap }, 70)
      // 设置样式
      this.$Excel.setExportCellStyle(
        map,
        `A2:${end}`,
        {
          s: {
            fill: { bgColor: { indexed: 64 }, fgColor: { rgb: 'dcdcdc' } },
            font: { color: { rgb: '000000' } },
            alignment: { horizontal: 'center', vertical: 'center' },
          },
        },
        function (
          cell,
          newCell,
          row,
          config,
          currentRow,
          currentCol,
          fieldKey
        ) {
          console.log(row, config, currentCol, fieldKey)
          // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
          return currentRow % 2 === 0 ? newCell : cell // 隔行隔列上色
        }
      )
      this.$Excel.setExportCellStyle(
        map,
        `A2:${end}`,
        {
          s: {
            font: { color: { rgb: '000000' } },
            alignment: { horizontal: 'center', vertical: 'center' },
          },
        },
        function (
          cell,
          newCell,
          row,
          config,
          currentRow,
          currentCol,
          fieldKey
        ) {
          console.log(row, config, currentCol, fieldKey)
          // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
          return currentRow % 2 === 1 ? newCell : cell // 隔行隔列上色
        }
      )
      // 设置样式
      this.$Excel.setExportCellStyle(
        map,
        `A1:${String.fromCharCode(64 + parseInt(cols))}1`,
        {
          s: {
            fill: { bgColor: { indexed: 64 }, fgColor: { rgb: '67c23a' } },
            font: { color: { rgb: 'ffffff' } },
            alignment: { horizontal: 'center', vertical: 'center' },
          },
        },
        function (
          cell,
          newCell,
          row,
          config,
          currentRow,
          currentCol,
          fieldKey
        ) {
          console.log(row, config, currentCol, fieldKey)
          return currentRow === 0 ? newCell : cell // 隔行隔列上色
        }
      )
      // 导出excel
      this.$Excel.exportExcel(map, name, 'xlsx', {
        extend: {
          '!cols': colConf,
        },
      })
    },
    //到期时间
    async ExpirationCommit(){
      console.log("Expiration date",this.expiration)
      if(!this.expiration)
      {
        this.$message({
          message: "日期不能为空",
          type: 'warning',
        })
        return
      }
      let res = await this.$api.device_expiration({
        sn: this.ExpirationSN,
        expiration: this.expiration.toString(),
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: res.msg,
          type: 'success',
        })
        this.onRefresh();
        this.ExpirationDialog = false
      }
    },
    // 充次
    async RechargeCommit() {
      this.chargeLoading = true
      try {
        let res = await this.$api.charge_device({
          sn: this.RechargeSN,
          times: this.RechargeEditor.toString(),
        })
        console.log(res)
        this.chargeLoading = false
        if (res.status == 1) {
          this.$message({
            message: res.msg,
            type: 'success',
          })
          this.onRefresh();
          this.RechargeTimesDialog = false
          if (this.info.atimes != -1 && res.atimes != -2) {
            this.info.atimes = res.atimes
            this.$store.dispatch('Login', this.info)
          }
        }
      } catch (error) {
        this.chargeLoading = false;
      }
    },
    // 修改名字
    async OpenModifyDialog(index) {
      this.modifyEditor = this.tbdata[index].name
      this.modifyUserPhone = this.tbdata[index].phone
      this.modifySN = this.tbdata[index].sn
      this.modifyCID = this.tbdata[index].cid
      this.modifyComments = ""
      this.modifyAddress = ""
      let res = await this.$api.get_comments_and_addr({
        sn: this.modifySN,
      })
      console.log(res)
      if (res.status == 1) {
        this.modifyComments = res.comments
        this.modifyAddress = res.addr.address
        this.modifySelectedArea = res.addr.area
      }
      this.modifyDeviceNameDialog = true
    },
    async ModifyNameAddrCommentCommit() {
      let res = await this.$api.edit_device_info({
        sn: this.modifySN,
        rename: this.modifyEditor,
        phone: this.modifyUserPhone,
        comm: this.modifyComments,
        area: this.modifySelectedArea,
        area_name: [codeToText[this.modifySelectedArea[0]], codeToText[this.modifySelectedArea[1]], codeToText[this.modifySelectedArea[2]]],
        address: this.modifyAddress,
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '修改成功',
          type: 'success',
        })
        this.onRefresh();
      }
      this.modifyDeviceNameDialog = false
    },
    // 设备分配

    async GetKids() {


    },
    async OpenAllocateDialog(index) {
      this.AllocSN = this.tbdata[index].sn

      let res = await this.$api.device_owner({
        sn: this.AllocSN
      })
      if (res.status == 1) {
        this.AllocList = res.data
        this.AllocValue = res.uid
      }
      else {
        this.AllocValue = ""
      }
      this.DeviceAllocateDialog = true
    },
    async AllocateCommit() {
      let res = await this.$api.bind_or_not({
        sn: this.AllocSN,
        uid: this.AllocValue,
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '修改成功',
          type: 'success',
        })
        this.onRefresh();
      }
      this.DeviceAllocateDialog = false
    },

    async onGetVUList() {
      let res = await this.$api.username_list()
      console.log(res)
      if (res.status == 1) {
        if (res.u != null)
          this.uselect = [{
            value: '',
            label: '不限'
          }, {
            value: '0',
            label: '未分配'
          }, ...res.u]
      } else {
        this.uselect = [{
          value: '',
          label: '不限'
        }, {
          value: '0',
          label: '未分配'
        }]

      }
    },
    //////
    // 主刷新
    async onRefresh() {
      let res = await this.$api.device_list_v2({
        name: this.searchinput + "",
        uid: this.usvalue,
        status: this.ssvalue,
        sn: this.searchinputSN + "",
        // le:this.leinput+"",
        p: this.currPage + "",
        z: this.currSize + "",
        sort: this.ordercheck,
      })
      console.log(res)
      if (res.status == 1) {
        this.tbdata = res.data
        if (this.currTotal != res.total) {
          this.currPage = 1
          this.currTotal = res.total // *2024年12月18日
          this.onRefresh()
        }

        if (this.ssvalue == "禁用") {
          this.enen = true
        } else {
          this.enen = false
        }
      }
    },
    // 获取页面上可用的按钮
    async PG_Query() {
      let res = await this.$api.pg_query({
        pg: this.pg_list,
      })
      // console.log("pg ->", res)
      if (res.status == 1) {
        this.pg_list = res.data
      }
      // console.log("====>", this.pg_list)
      // let btnNum = 0
      // for (let k in this.pg_list)
      //   if (this.pg_list[k])
      //     btnNum++

      this.btnwidth = 610

      // if (btnNum >= 4) {
      //   this.btnwidth = this.btnwidth + (btnNum - 3) * 90
      // }


    },
    async DeleteDeviceCommit(index) {
      let res = await this.$api.delete_device({
        sn: this.tbdata[index].sn
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '删除成功',
          type: 'success',
        })
        this.onRefresh();
      }
    },
    temperatureFormatter(row, column, cellValue) {
      return cellValue + '℃';
    },
    handleSizeChange(val) {
      this.currSize = val
      this.currPage = 1
      this.onRefresh()
    },
    handleCurrentChange(val) {
      this.currPage = val
      this.onRefresh()
    },
    // 地图
    async OpenMapDialog(index) {
      this.MapURL = ''
      this.MapTitle = this.tbdata[index].name
      this.MapSN = this.tbdata[index].sn
      this.MapMarked = ''
      this.MapAddressInput = ""
      let res = await this.$api.get_location({
        type: 'auto',///this.MapSelect,
        sn: this.MapSN
      })
      console.log(res)
      if (res.status == 1) {
        this.MapURL = res.data.url
        this.MapAddress = res.data.addr
        this.MapSelect = res.data.type
        this.MapAddressInput = res.data.address
        this.mapSelectedArea = res.data.area
        this.MapMarked = res.data.marked
      }
      this.MapDialog = true
    },
    async GetMapInfo() {
      this.MapURL = ''
      this.MapMarked = ''
      this.MapMarked = ''
      this.MapAddressInput = ""
      let res = await this.$api.get_location({
        type: this.MapSelect,
        sn: this.MapSN
      })
      console.log(res)
      if (res.status == 1) {
        this.MapURL = res.data.url
        this.MapAddress = res.data.addr
        this.MapSelect = res.data.type
        this.MapAddressInput = res.data.address
        this.mapSelectedArea = res.data.area
        this.MapMarked = res.data.marked
      }
    },
    async LocationMarkCommit() {
      let res = await this.$api.location_commit({
        sn: this.MapSN + "",
        mark: this.MapMarked + "",
        area: this.mapSelectedArea,
        area_name: [codeToText[this.mapSelectedArea[0]], codeToText[this.mapSelectedArea[1]], codeToText[this.mapSelectedArea[2]]],
        address: this.MapAddressInput + "",
      })
      console.log(res)
      if (res.status == 1) {
        this.$notify({
          title: '成功',
          message: '标注成功',
          type: 'success'
        });
        this.MapSelect = '标注定位'
        this.GetMapInfo()
      }
    },
    getStatusType(status) {
      switch (status) {
        case '在线':
          return 'success';
        case '离线':
          return 'info';
        case '保温':
          return 'warning';
        case '预热':
          return 'warning';
        case '运行':
          return 'danger';
        default:
          return 'info';
      }
    }
  },
}
</script>

<style scoped>
.search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


.detail-card .el-button {
  margin: 8px;
  margin-bottom: 14px;
  font-size: 16px;
  width: 92%
}
</style>
